import React, { useState, useEffect } from 'react'
import { FormGroup, FloatingLabel, Form, Button } from 'react-bootstrap'
import "./NewTopic.scss";
import ReactGiphySearchbox from 'react-giphy-searchbox';
import "bootstrap-icons/font/bootstrap-icons.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Embed from 'react-embed';
import { InstagramEmbed, FacebookEmbed, YouTubeEmbed, TwitterEmbed, TikTokEmbed, LinkedInEmbed } from 'react-social-media-embed';
import { imageUpload } from '../../services/RepoService';
import { userInfo } from 'os';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getBaseHref } from "../../url-base-href-ts";

const BASE_HREF = getBaseHref();


function auto_grow(element) {
    element.target.style.height = "5px";
    element.target.style.height = (element.target.scrollHeight) + "px";
}

function onInputClick(event) {
    event.target.value = ''
}

function NewTopic({ submitTopicFn }) {
    const [data, setData] = useState({ title: '', summary: '', gifUrl: '', imageUrl: '', socialUrl: '' });
    const [urlPreview, setUrlPreview] = useState("");
    const [showFaceBookEmbed, updateFaceBookEmbed] = useState(false);
    const [showInstaEmbed, updateInstaEmbed] = useState(false);
    const [showYoutubeEmbed, updateYoutubeEmbed] = useState(false);
    const [showTwitterEmbed, updateTwitterEmbed] = useState(false);
    const [showTiktokEmbed, updateTiktokEmbed] = useState(false);
    const [showLinkedInEmbed, updateLnkeinEmbed] = useState(false);
    const [embedUrl, updateEmbedUrl] = useState("");

    function handleChange(e) {
        setUrlPreview(URL.createObjectURL(e.target.files[0]));

        let img = e.target.files[0];
        const user = JSON.parse(localStorage.getItem('user'));

        const formData = new FormData();

        formData.append("title", "")
        formData.append("description", "")
        formData.append("source", "")
        formData.append("tickers", "")
        formData.append("create_by", user?.id.toString());
        formData.append("file", img)

        axios({
            method: "post",
            url: BASE_HREF + "/image_catalog/addImage",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data", 'mode': 'no-cors',
                'mimeType': 'multipart/form-data',
                'dataType': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => {
            setData({ ...data, imageUrl: response?.data?.result?.imageURL });
        }, (error) => {
            console.log(error);
        });
    }

    function checkURL(e) {

        var urlcaptured = e.clipboardData.getData('Text');

        if (urlcaptured.includes('youtube')) {
            updateYoutubeEmbed(true);
            updateFaceBookEmbed(false);
            updateTwitterEmbed(false);
            updateInstaEmbed(false);
            updateTiktokEmbed(false);
            updateLnkeinEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            setData({ ...data, socialUrl: urlcaptured })
        }
        else if (urlcaptured.includes('twitter')) {
            updateYoutubeEmbed(false);
            updateFaceBookEmbed(false);
            updateTwitterEmbed(true);
            updateInstaEmbed(false);
            updateTiktokEmbed(false);
            updateLnkeinEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            setData({ ...data, socialUrl: urlcaptured })
        }
        else if (urlcaptured.includes('instagram')) {
            updateYoutubeEmbed(false);
            updateTwitterEmbed(false);
            updateInstaEmbed(true);
            updateFaceBookEmbed(false);
            updateTiktokEmbed(false);
            updateLnkeinEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            setData({ ...data, socialUrl: urlcaptured })
        }
        else if (urlcaptured.includes('facebook')) {
            updateYoutubeEmbed(false);
            updateTwitterEmbed(false);
            updateInstaEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            updateFaceBookEmbed(true);
            updateTiktokEmbed(false);
            updateLnkeinEmbed(false);
            setData({ ...data, socialUrl: urlcaptured })
        }
        else if (urlcaptured.includes('tiktok')) {
            updateYoutubeEmbed(false);
            updateTwitterEmbed(false);
            updateInstaEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            updateFaceBookEmbed(false);
            updateTiktokEmbed(true);
            updateLnkeinEmbed(false);
            setData({ ...data, socialUrl: urlcaptured })
        }
        else if (urlcaptured.includes('linked')) {
            updateYoutubeEmbed(false);
            updateTwitterEmbed(false);
            updateInstaEmbed(false);
            setUrlPreview('');
            updateEmbedUrl(urlcaptured);
            updateFaceBookEmbed(false);
            updateTiktokEmbed(false);
            updateLnkeinEmbed(true);
            setData({ ...data, socialUrl: urlcaptured })
        }
    }

    return (
        <div className="new-item-topic-wrapper">
            <FormGroup className="mb-3">
                <Form.Control type="text" placeholder="Write title for your Topic" required
                    onChange={(e) => setData({ ...data, title: e.target.value })}
                    className="p-2"
                />
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Control as="textarea" id="topicText" onPaste={(e) => checkURL(e)} onInput={(e) => auto_grow(e)} style={{ resize: 'none', overflow: 'hidden', minHeight: '60px' }}
                    placeholder="Write detailed summary of your Topic and Paste Social Media URL for reference (Youtube, Twitter, Instagram, Facebook)" required
                    onChange={(e) => setData({ ...data, summary: e.target.value })}
                />
            </FormGroup>
            <FormGroup className="mb-3">
                <p> <label htmlFor="fileUpload">
                    <img id="imageUpload" src={process.env.PUBLIC_URL + `/images/imageUpload.png`} /> Upload Image
                </label>
                    <input type="file" id="fileUpload" accept="image/*" style={{ display: 'none' }} onClick={onInputClick} onChange={(e) => handleChange(e)}></input>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <label htmlFor="imageSearch"> <img id="imageSearch" src={process.env.PUBLIC_URL + `/images/ImageSearch.png`} />&nbsp; Search Image</label>                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <label htmlFor='gifSearch'>
                        <Popup trigger={<p> <img id="gifSearch" src={process.env.PUBLIC_URL + `/images/gifSearch.png`} /> Search GIF </p>} position="right center" contentStyle={{ width: 'auto' }} closeOnDocumentClick modal>
                            {close => (<div> <button className="close" id="closePopUp" onClick={close}>Close</button>
                                <ReactGiphySearchbox
                                    apiKey="6EX8Pn8iDBLrviUY8yJiEJ044sXYSZcE" // Required: get your on https://developers.giphy.com
                                    onSelect={item => {
                                        console.log(item);
                                        setUrlPreview(item.images.fixed_height.url)
                                        setData({ ...data, gifUrl: item.images.fixed_height.url });
                                        updateYoutubeEmbed(false);
                                        updateTwitterEmbed(false);
                                        updateInstaEmbed(false);
                                        updateFaceBookEmbed(false);
                                        updateTiktokEmbed(false);
                                        updateLnkeinEmbed(false);
                                        document.getElementById('closePopUp').click();
                                    }}
                                    masonryConfig={[
                                        { columns: 4, imageWidth: 150, gutter: 4 },
                                        { mq: "1000px", columns: 4, imageWidth: 150, gutter: 4 }
                                    ]}
                                />
                            </div>
                            )}
                        </Popup>
                        {/* <img id="gifSearchImg" src={process.env.PUBLIC_URL + `/images/gifSearch.png`}/> */}
                    </label>
                </p>
            </FormGroup>
            <FormGroup className="mb-3">
                <p>
                    {/* https://codesandbox.io/s/z20llylz9l?file=/index.js  flicker nice iplementation 
                <ReactFlickerPhotos
                    UserID="81285904@N05"
                    // ClickCallback={ClickCallback}
                    ImagesPerPage={10} />
                */}
                    {urlPreview.length > 0 && <p>     Preview: &nbsp; <button title="Clear Preview" onClick={() => setUrlPreview('')} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> <br />
                        <img src={urlPreview} style={{ maxHeight: '500px', maxWidth: '500px' }} />
                        {/* <button className="clear" id="clearPreview" onClick={() => setUrlPreview('')}>Clear Preview</button> */}
                    </p>
                    }
                </p>
            </FormGroup>
            {showYoutubeEmbed && <YouTubeEmbed url={embedUrl} />}
            {showTwitterEmbed && <TwitterEmbed url={embedUrl} />}
            {showFaceBookEmbed && <FacebookEmbed url={embedUrl} />}
            {showInstaEmbed && <InstagramEmbed url={embedUrl} />}
            {showTiktokEmbed && <TikTokEmbed url={embedUrl} />}
            {showLinkedInEmbed && <LinkedInEmbed url={embedUrl} />}

            <div className="submit-button-wrapper" style={{ justifyContent: 'center' }}>
                <Button variant="dark" type="button" onClick={() => submitTopicFn(data)}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default NewTopic
