import React, { useState, useRef } from 'react'
import { FloatingLabel, Form, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect, useHistory } from 'react-router';
import SignUp from './Signup';
import { SessionService } from "./../../services/Session.service";
import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { GoogleLoginButton, AppleLoginButton } from 'react-social-login-buttons';
import Popup from 'reactjs-popup';
import Privacy from '../Privacy/Privacy';
// Singleton for all Service / Classes
export const Injector = {
    session: new SessionService()
}

function OnSuccess(res) {
    var profile = res.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    console.log('success:', profile);
   const dataGoogle =  {"id": profile.getId(),
    "firstName":profile.getName(),
    "lastName": profile.getName(),
    "userId":profile.getEmail(),
    "phoneNumber": null,
    "emailId":profile.getEmail(),
    "password":null,"lastlogout":null,"regfrom":"normal","location":null,"tradertype":null,"tradingtype":null,"experience":null,"verify_status":0}
     localStorage.setItem('user', JSON.stringify(dataGoogle));
     window.location.href = process.env.PUBLIC_URL
     //Injector.session.postLogin({ emailId: profile.getEmail(), password: profile.getId() });
     Injector.session.postLogin({ emailId: profile.getEmail(), password: '' });
     Injector.session.createUser(dataGoogle);
};
const OnFailure = (err) => {
    console.log('failed:', err);
};
function redirect() {
    var url = "https://stockjabber.com/";
    window.location.href = url;
}

function Login({ state, setState }: any) {
    const { session } = state;
    const navigation = useHistory();
    const ref = useRef(null);
    const [globalState, setGlobalState] = useState(Injector);
    const [login, setLogin] = useState({
        emailId: '',
        password: ''
    });
    const updateLogin = (key, val) => {
        setLogin({ ...login, [key]: val })
    }

    const doLogin = async () => {
        if (login.emailId && login.password && ref.current.checked) {
            try {
                const user = await session.postLogin(login);
                session.user = user;
                setState({ ...state, session });
                const loginRedirect = sessionStorage.getItem(`redirect_after_login`);
                if (loginRedirect) {
                    sessionStorage.removeItem(`redirect_after_login`);
                    navigation.push(loginRedirect);
                }
                else {
                    // alert('No login data on session storage');
                    navigation.push('/');
                }
            } catch (e) {
                console.log(e);
                alert('Login Failed. Please try again');
            }
        } else alert('Please enter email password and select checkbox');
    }
    return (
        <>
            {session?.user && <Redirect to="/" />}
            <div className="mt-md-5">
                <div className="row">
                    <div className="col-md-7 ">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/loginPic.jpg`} />
                    </div>
                    <div className="col-md-5">
                        <div className="m-5">
                            <FormGroup className="mb-3">
                                <h5>Login</h5>
                                <FloatingLabel controlId="login-email" label="Email" className="mb-3">
                                    <Form.Control type="text" placeholder="Email" required
                                        onChange={(event) => updateLogin('emailId', event.target.value)}
                                    />
                                </FloatingLabel>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="password" label="Password" className="mb-3">
                                    <Form.Control type="password" placeholder="Password" required
                                        onChange={(event) => updateLogin('password', event.target.value)}
                                    />
                                </FloatingLabel>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <div className="text-center">
                                    <p style={{ float: 'left' }}><input ref={ref} type="checkbox" id="acceptTerms" checked></input>&nbsp;<a href={`${process.env.PUBLIC_URL}/privacy-policy`} target="_blank">Please read terms and private policy</a></p>   
                                     {/* <a style={{ float: 'right' }}>Forgot Password</a>  */}
                                     <br />
                                    <button className="btn btn-info" onClick={() => doLogin()} style={{ borderRadius: '15px', width: '60%', height: '50px' }}>
                                        <b>Login</b>
                                    </button>
                                    {/* <GoogleLogin
                                        clientId={"254675741531-om2q76vi5b4llcnqif7btlf7hehh7o71.apps.googleusercontent.com"}
                                        buttonText="Sign in with Google"
                                        render={renderProps => (
                                            <GoogleLoginButton onClick={renderProps.onClick} style={{ borderRadius: '15px', border: '2px solid black', width: '60%', marginLeft: '20%' }}> Sign in with Google</GoogleLoginButton>
                                        )}
                                        onSuccess={OnSuccess}
                                        onFailure={OnFailure}
                                        cookiePolicy={'single_host_origin'}
                                        isSignedIn={true}
                                    /> */}
                                </div>
                                {/* <button style={{ float: 'left', borderRadius:'10px', marginLeft:'5px' }}>Forgot Password</button> */}

                            </FormGroup>
                            <br />
                            <div className="row">
                                <p className="col-sm-6 d-none d-md-block">
                                    <b>New to Stock Jabber</b>
                                </p>
                                <p className="col-sm-6" style={{ float: 'right' }}>
                                    <Popup className='modal-dialog-scrollable' trigger={<button style={{ float: 'right', borderRadius: '10px', width: 'auto' }}>Create Account</button>} position="top center" contentStyle={{ width: 'auto', maxHeight:'80%',overflow:'auto' }} closeOnDocumentClick modal>
                                        {close => (<div> <button className="close" style={{ borderRadius: '10px', width: 'auto' }} onClick={close}>Close</button>
                                            <h4 className="text-center"> Create Account</h4>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Converse about Stocks,Finance,Topics and get answers for your Finance Questions</p>
                                            <SignUp state={globalState} setState={setGlobalState} />
                                        </div>
                                        )}
                                    </Popup>
                                </p>
                                {/* <p className="col-sm-5 offset-md-1">
                             <AppleLogin clientId="com.react.apple.login" redirectURI="https://redirectUrl.com" />
                            <AppleLogin
                                clientId="YOUR_CLIENT_ID"
                                redirectURI="YOUR_REDIRECT_URL"
                                render={renderProps => (
                                    <AppleLoginButton onClick={renderProps.onClick}>Apple Signin</AppleLoginButton>
                                )}
                            // usePopup={true}
                            // callback={this.appleResponse} // Catch the response
                            />
                            </p> */}
                            </div>
                            <div className="row">
                                <p className="col-sm-6 d-none d-md-block">
                                    <b>Want to Explore More</b>
                                </p>
                                <p className="col-sm-6" style={{ float: 'right' }}>
                                    <button onClick={redirect} style={{ float: 'right', borderRadius: '10px', height: '30px', width: 'auto' }}>&nbsp;&nbsp;SignUp Later&nbsp;&nbsp;</button>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;